<section class="agency blog-sec blog-sidebar single_blog_item">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="blog-block">
            <div class="blog-box">
              <div class="overflow-hidden">
                <img class="img-fluid blur-up lazyload" [src]="blogData.img" alt="blog">
              </div>
            </div>
          </div>
          <div class="blog-text m-t-20">
            <h6>{{blogData.date}}</h6>
            <a href="#">
              <h3 class="blog-head">{{blogData.title}}</h3>
            </a>
            <div class="blog-divider"></div>
            <div class="blog-description">
              <p>{{blogData.detail1}}</p>
              <p>{{blogData.detail2}}</p>
              <h5>{{blogData.name}}</h5>
              <h5 class="pull-right">{{blogData.like}} <i class="fa fa-heart-o"></i>{{blogData.comment}} <i class="fa fa-comments-o"></i></h5>
            </div>
          </div>
          <!-- Comment Section Start -->
          <h3 class="page-header-comment">{{blogData.section}}</h3>
          <div class="comment-list blog_comment">
            <div class="comment_sec" *ngFor="let data of blogData.commentSection">
              <article class="row">
                <div class="" [ngClass]="data.Id==2 ? 'col-11 offset-1' : 'col-12'">
                  <div class="blog_center" [ngClass]="data.Id==2 ? re_comment : ''">
                    <div>
                      <figure class="thumbnail">
                        <img [src]="data.commentImg" alt="">
                      </figure>
                    </div>
                    <div class="blog_user arrow left">
                      <div class="panel-body">
                        <div class="text-left">
                          <div class="comment-user">
                            <i class="fa fa-user"></i>
                            <h6>{{data.commentName}}</h6>
                          </div>
                          <div class="comment-date">
                            <i class="fa fa-clock-o"></i>
                            <h6>{{data.commentDate}}</h6>
                          </div>
                        </div>
                        <div class="comment-post">
                          <p>{{data.commentDescription}}</p>
                        </div>
                        <a href="#" class="link-btn">
                          <i class="fa fa-reply"></i> {{data.commentReplayTag}}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  