import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSliderModule } from "ngx-slider-v2";
import { CarouselModule } from "ngx-owl-carousel-o";
import { SwiperModule } from "swiper/angular";
import { AlzakaaAboutComponent } from "./Alzakaa/Alzakaa-about/Alzakaa-about.component";
import { AlzakaaDownloadComponent } from "./Alzakaa/Alzakaa-download/Alzakaa-download.component";
import { AlzakaaFooterComponent } from "./Alzakaa/Alzakaa-footer/Alzakaa-footer.component";
import { AlzakaaHeaderComponent } from "./Alzakaa/Alzakaa-header/Alzakaa-header.component";
import { AlzakaaNavComponent } from "./Alzakaa/Alzakaa-nav/Alzakaa-nav.component";
import { AlzakaaPricingComponent } from "./Alzakaa/Alzakaa-pricing/Alzakaa-pricing.component";
import { AlzakaaScreenshotsComponent } from "./Alzakaa/Alzakaa-screenshots/Alzakaa-screenshots.component";
import { AlzakaaServicesComponent } from "./Alzakaa/Alzakaa-services/Alzakaa-services.component";
import { AlzakaaSubscribeComponent } from "./Alzakaa/Alzakaa-subscribe/Alzakaa-subscribe.component";
import { AlzakaaVideoComponent } from "./Alzakaa/Alzakaa-video/Alzakaa-video.component";
//Alzakaa Layouts
import { AlzakaaComponent } from "./Alzakaa/Alzakaa.component";
import { LayoutsRoutingModule } from "./layouts-routing.module";
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    AlzakaaAboutComponent,
    AlzakaaComponent,
    AlzakaaHeaderComponent,
    AlzakaaNavComponent,
    AlzakaaServicesComponent,
    AlzakaaAboutComponent,
    AlzakaaDownloadComponent,
    AlzakaaScreenshotsComponent,
    AlzakaaPricingComponent,
    AlzakaaVideoComponent,
    AlzakaaSubscribeComponent,
    AlzakaaFooterComponent,
  ],
  imports: [
    CommonModule,
    LayoutsRoutingModule,
    CarouselModule,
    NgbModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSliderModule,
    SwiperModule,
  ],
  exports: [
    AlzakaaNavComponent,
    AlzakaaFooterComponent,
  ],
})
export class LayoutsModule {}
