<app-Alzakaa-nav></app-Alzakaa-nav>


<section class="app1 header" id="home">
  <div class="app1-header bg" [ngStyle]="{'background-image': 'url(assets/images/app_landing1/header-1-bg.png)'}">
      <div class="container">
          <div class="row">
              <div class="col-lg-6 col-md-7">
                  <div class="center-text">
                      <div>
                          <div class="header-text">
                              <div class="sub-head">Al Zakaa Blogs</div>
                          </div>
                         
                      </div>
                  </div>
              </div>
             
              <div class="squares">
                  <img alt="" src="assets/images/app_landing1/Ellipse.png" class="img-fluid img-1 set-abs">
                  <img alt="" src="assets/images/app_landing1/Ellipse.png" class="img-fluid img-2 set-abs">
              </div>
              <div class="animation-circle-inverse">
                  <i></i>
                  <i></i>
                  <i></i>
              </div>
          </div>
      </div>
  </div>
</section>

<!-- <section class="agency blog blog-sec">
  <div class="container no-side">
    <div class="row">
      <div class="col-xl-4 col-md-6" *ngFor="let blog of blogData;">
        <div class="blog-agency">
          <div class="blog-contain">
            <img [src]="blog.img" alt="Blog Image" class="img-fluid">
            <div class="img-container center-content">
              <div class="center-content">
                <div class="blog-info">
                  <div class="m-b-20">
                    <div class="center-text">
                      <i class="fa fa-clock-o m-r-10" aria-hidden="true"></i>
                      <h6 class="m-r-25 font-blog">{{ blog.date }}</h6>
                      <i class="fa fa-map-marker m-r-10" aria-hidden="true"></i>
                      <h6 class="font-blog">{{ blog.type }}</h6>
                    </div>
                  </div>
                  <h5 class="blog-head font-600">{{ blog.title }}</h5>
                  <p class="para2">{{ blog.content }}</p>
                  <div class="btn-bottom m-t-20">
                    <a [routerLink]="'/blogs/' + blog.id" class="text-uppercase">{{ blog.btn }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<div style="display: flex;justify-content: center; font-size: 17px; padding-top: 50px;"> No Blogs To Show!</div>
<app-Alzakaa-footer></app-Alzakaa-footer>
