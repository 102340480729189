import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-Alzakaa-about',
  templateUrl: './Alzakaa-about.component.html',
  styleUrls: ['./Alzakaa-about.component.scss']
})
export class AlzakaaAboutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  counter = [
    { 
     img:"assets/images/app_landing1/about/clients.png",
     count: 10,
     text: 'clients'
    },
    { 
      img:"assets/images/app_landing1/about/project.png",
      count: 15,
      text: 'Projects'
     },
     { 
      img:"assets/images/app_landing1/about/experience.png",
      count: 3,
      text: 'Experience'
     },
    //  { 
    //   img:"assets/images/app_landing1/about/coffee.png",
    //   count: 85,
    //   text: 'lorem'
    //  },
    
  ]
}
