import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../../service/nav.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public menuItems: Menu[];
  public openSide: boolean = false;
  public activeItem: string = 'home';
  public active: boolean = false;
  public activeChildItem: string = ''; 
  public overlay: boolean = false;
  
  constructor(public navServices: NavService) { }
 
  ngOnInit() {
    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems;
    });
  }

  toggleSidebar() {
    this.openSide = !this.openSide;
  }

  closeOverlay() {
    this.openSide = false;
  }

  setActive(menuItem: string) {
    this.activeItem = this.activeItem === menuItem ? '' : menuItem;
  }

  isActive(item: string): boolean {
    return this.activeItem === item;
  }

  setChildActive(subMenu: string) {
    this.activeChildItem = this.activeChildItem === subMenu ? '' : subMenu;
  }

  ischildActive(subMenu: string): boolean {
    return this.activeChildItem === subMenu;
  }
}
