import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { BlogsComponent } from './blogs/blogs.component';
import { ServicesComponent } from './services/services.component';
import { BlogComponent } from './blogs/blog/blog.component';
import { ContactUsComponent } from './contact-us/contact.component';

const routes: Routes = [
  {
    path: 'about-us',
    component: AboutUsComponent,
    data: { title: "About Us", breadcrumb: "About Us" }
  },
  {
    path: 'blogs',
    component: BlogsComponent,
    data: { title: 'Blogs', breadcrumb: 'Blogs' },
    children: [
      {
        path: 'blogs/:id',
        component: BlogComponent,
        data: { title: 'Blog', breadcrumb: 'Blog' },
      },
    ],
  },
  {
    path: 'services',
    component: ServicesComponent,
    data: { title: "Services", breadcrumb: "Services" }
  },
  {
    path: 'contact',
    component: ContactUsComponent,
    data: { title: "Contact", breadcrumb: "Contact" }
  },
  // Add more routes for other pages here
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
