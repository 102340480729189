<!-- download section Start-->

<section class="app1 download">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-6 hide-on-init" data-aos="fade-right"  [@fadeInLeft]="fadeInLeft" data-aos-duration="1500">
                <div class="center-content center-mobile">
                    <img src="assets/images/app_landing1/service1.png" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1 col-md-6">
                <div class="center-text">
                    <div>
                        <div class="download-img d-flex">
                            <div class="set-relative">
                                <img src="assets/images/app_landing1/download/download.png" alt=""
                                     class="download-icon img-fluid">
                                <div class="set-abs elipse center-abs">
                                    <img src="assets/images/app_landing1/download/service.png" alt="">
                                </div>
                            </div>
                            <div class="center-img-content m-l-15 project">
                                <h3 class="m-b-5" style="padding-left: 50px;">Al Zakaa Projects</h3>
                            </div>
                        </div>
                        <div class="information">
                            <p  >Alzakaa Projects is dedicated to delivering innovative and high-quality solutions that empower businesses and communities. With a focus on excellence and client satisfaction, we bring together cutting-edge technology and creative thinking to create projects that make a meaningful impact. From concept to completion, Alzakaa Projects is your trusted partner in achieving outstanding results.<br> You Can contact us to see all our projects </p>
                        </div>
                        <div class="link-horizontal">
                            <ul>
                                <li>
                                    <a class="icon-btn">
                                    <div class="icons"> 
                                        <i class="fa fa-graduation-cap  center-content shadow" aria-hidden="true"></i>
                                        <h6 class="text-center font-primary">School Management</h6>
                                    </div>
                                    </a>
                                </li>
                                <li>
                                    <a class="icon-btn">
                                    <div class="icons"> 
                                        <i class="fa fa-money center-content shadow" aria-hidden="true"></i>
                                        <h6 class="text-center font-primary">Accounting System</h6>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a class="icon-btn">
                                    <div class="icons"> 
                                        <i class="fa fa-solid fa-shopping-cart  center-content shadow" aria-hidden="true"></i>
                                        <h6 class="text-center font-primary">E-commerce Website </h6>
                                        </div>
                                    </a>
                                </li>
                                
                            </ul>
                            <ul style="margin-top: 20px;">
                                <!-- <li>
                                    <a class="icon-btn">
                                    <div class="icons"> 
                                        <i class="fa fa-solid fa-user center-content shadow" aria-hidden="true"></i>
                                        <h6 class="text-center font-primary">UI & UX Design</h6>
                                        </div>
                                    </a>
                                </li> -->
                                <!-- <li>
                                    <a class="icon-btn">
                                    <div class="icons"> 
                                        <i class="fa fa-solid fa-bug center-content shadow" aria-hidden="true"></i>
                                        <h6 class="text-center font-primary">Maintenance & Support</h6>
                                    </div>
                                    </a>
                                </li> -->
                                <!-- <li>
                                    <a class="icon-btn">
                                    <div class="icons"> 
                                        <i class="fa fa-regular fa-chart-simple center-content shadow" aria-hidden="true"></i>
                                        <h6 class="text-center font-primary">Digital Marketing</h6>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a class="icon-btn">
                                    <div class="icons"> 
                                        <i class="fa fa-solid fa-people-roof center-content shadow" aria-hidden="true"></i>
                                        <h6 class="text-center font-primary">Content Management </h6>
                                        </div>
                                    </a>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- download section end-->
