import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ToastrModule } from "ngx-toastr";
import { AboutUsComponent } from "./about-us/about-us.component";
import { PagesRoutingModule } from "./pages-routing.module";
import { SharedModule } from "../shared/shared.module";
import { BlogsComponent } from "./blogs/blogs.component";
import { ServicesComponent } from "./services/services.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LayoutsModule } from "../layouts/layouts.module";
import { ContactUsComponent } from "./contact-us/contact.component";

@NgModule({
  declarations: [
    AboutUsComponent,
    BlogsComponent,
    ServicesComponent,
    ContactUsComponent
    // Only declare components that are unique to this module
  ],
  imports: [
    CommonModule,
    RouterModule,
    ToastrModule.forRoot(),
    SharedModule,
    PagesRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutsModule
    
  ],
})
export class PagesModule {}
