import { Component, OnInit } from '@angular/core';
import { blogBasicDB } from '../../shared/data/blog/blogs/column';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {
  public blogData: any
  
  constructor() {
    this.blogData = blogBasicDB.column;
   }

  ngOnInit() {
  }

}
