<app-Alzakaa-nav></app-Alzakaa-nav>
<!-- header section Start-->
<section class="app1 header" id="home">
  <div class="app1-header bg" [ngStyle]="{'background-image': 'url(assets/images/app_landing1/header-1-bg.png)'}">
      <div class="container">
          <div class="row">
              <div class="col-lg-6 col-md-7">
                  <div class="center-text">
                      <div>
                          <div class="header-text">
                              <div class="sub-head">About Us</div>
                          </div>
                          <div class="header-sub-text">
                              <p class="text-white p-light">   At Al zakaa , we are dedicated to transforming ideas into
                                impactful digital solutions. Founded on the principles of
                                innovation, quality, and client satisfaction, we specialize in
                                delivering cutting-edge software and technology services
                                tailored to meet the unique needs of businesses across various
                                industries.</p>
                          </div>
                          
                      </div>
                  </div>
              </div>
            
              <div class="squares">
                  <img alt="" src="assets/images/app_landing1/Ellipse.png" class="img-fluid img-1 set-abs">
                  <img alt="" src="assets/images/app_landing1/Ellipse.png" class="img-fluid img-2 set-abs">
              </div>
              <div class="animation-circle-inverse">
                  <i></i>
                  <i></i>
                  <i></i>
              </div>
          </div>
      </div>
  </div>
</section>
<!-- header section end-->


<!-- <section class="gym format">
  <div class="container">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div class="center-text">
          <div class="text-center">
            <div class="format-head-text">
              <h3 class="about-font-header gradient-text">about us</h3>
            </div>
            <div class="format-sub-text mb-0">
              <p class="p-light about-para">
                At Al zakaa , we are dedicated to transforming ideas into
                impactful digital solutions. Founded on the principles of
                innovation, quality, and client satisfaction, we specialize in
                delivering cutting-edge software and technology services
                tailored to meet the unique needs of businesses across various
                industries.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section class="gym format">
  <div class="container">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div class="center-text">
          <div class="text-center">
            <div class="format-head-text">
              <h3 class="about-font-header gradient-text">Our Mission</h3>
            </div>
            <div class="format-sub-text mb-0">
              <p class="p-light about-para">
                Our mission is to empower businesses with innovative technology
                solutions that drive growth and efficiency. We are committed to
                understanding our clients' challenges and providing bespoke
                solutions that exceed their expectations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="gym format">
  <div class="container">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div class="center-text">
          <div class="text-center">
            <div class="format-head-text">
              <h3 class="about-font-header gradient-text">Our Vision</h3>
            </div>
            <div class="format-sub-text mb-0">
              <p class="p-light about-para">
                We envision a world where technology seamlessly integrates with
                business to unlock new possibilities and enhance lives. By
                staying at the forefront of technological advancements, we aim
                to lead the industry in delivering transformative digital
                experiences.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="gym format">
  <div class="container">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div class="center-text">
          <div class="text-center">
            <div class="format-head-text">
              <h3 class="about-font-header gradient-text">Our Approach</h3>
            </div>
            <div class="format-sub-text mb-0">
              <p class="p-light about-para">
                We take a collaborative approach to every project, working
                closely with our clients to understand their goals and tailor
                our solutions accordingly. Our agile development methodology
                ensures flexibility and adaptability, allowing us to respond
                quickly to changes and deliver solutions that meet evolving
                needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="gym format">
  <div class="container">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div class="center-text">
          <div class="text-center">
            <div class="format-head-text">
              <h3 class="about-font-header gradient-text">Why Choose Us</h3>
            </div>
            <div class="format-sub-text mb-0">
              <p class="p-light about-para">
                We combine technical expertise with a deep understanding of
                industry trends to provide solutions that not only meet but
                anticipate the needs of the modern business landscape. Our
                commitment to quality, innovation, and client satisfaction makes
                us a trusted partner in achieving your digital transformation
                goals. Join us on our journey to shape the future of technology.
                Together, we can create solutions that drive success and make a
                meaningful impact.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="event speaker set-relative" id="speaker">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="title title2 title-inner">
          <div class="main-title">
            <h2 class="font-primary borders text-center text-uppercase m-b-0">
              <span>Our team</span>
            </h2>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 speker-container">
        <div class="text-center">
          <div class="team-img">
            <img src="assets/images/event/l3-2.png" alt="" class="img-fluid" />
            <div class="overlay"></div>
            <div class="social">
              <ul>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-facebook center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-twitter center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-google center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-globe center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="employee">
            <h5 class="e-name text-center">Abdelrahman Zakaria</h5>
            <h6 class="post text-center">CEO</h6>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 speker-container">
        <div class="text-center">
          <div class="team-img">
            <img src="assets/images/event/l3-2.png" alt="" class="img-fluid" />
            <div class="overlay"></div>
            <div class="social">
              <ul>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-facebook center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-twitter center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-google center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-globe center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="employee">
            <h5 class="e-name text-center">Mouhamad Khaled</h5>
            <h6 class="post text-center">Team Leader</h6>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 speker-container">
        <div class="text-center">
          <div class="team-img">
            <img src="assets/images/event/l3-1.png" alt="" class="img-fluid" />
            <div class="overlay"></div>
            <div class="social">
              <ul>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-facebook center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-twitter center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-google center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-globe center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="employee">
            <h5 class="e-name text-center">Walid khoder</h5>
            <h6 class="post text-center">Full Stack Developer </h6>
          </div>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 speker-container">
        <div class="text-center">
          <div class="team-img">
            <img src="assets/images/event/l3-2.png" alt="" class="img-fluid" />
            <div class="overlay"></div>
            <div class="social">
              <ul>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-facebook center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-twitter center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-google center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-globe center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="employee">
            <h5 class="e-name text-center">bader zakaria</h5>
            <h6 class="post text-center">ML Specialist</h6>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 speker-container">
        <div class="text-center">
          <div class="team-img">
            <img src="assets/images/event/l3-2.png" alt="" class="img-fluid" />
            <div class="overlay"></div>
            <div class="social">
              <ul>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-facebook center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-twitter center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-google center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-globe center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="employee">
            <h5 class="e-name text-center">Abdelghaffar Khaled</h5>
            <h6 class="post text-center">Sales Force Developer</h6>
          </div>
        </div>
      </div>
      
      <div class="col-md-3 col-sm-6 speker-container">
        <div class="text-center">
          <div class="team-img">
            <img src="assets/images/event/l3-3.png" alt="" class="img-fluid" />
            <div class="overlay"></div>
            <div class="social">
              <ul>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-facebook center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-twitter center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-google center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-globe center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="employee">
            <h5 class="e-name text-center">Abdelkader Mahmoud</h5>
            <h6 class="post text-center">App Developer</h6>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 speker-container">
        <div class="text-center">
          <div class="team-img">
            <img src="assets/images/event/l3-4.png" alt="" class="img-fluid" />
            <div class="overlay"></div>
            <div class="social">
              <ul>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-facebook center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-twitter center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-google center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-globe center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="employee">
            <h5 class="e-name text-center">Nour el din Bakkar</h5>
            <h6 class="post text-center">App Developer </h6>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 speker-container">
        <div class="text-center">
          <div class="team-img">
            <img src="assets/images/event/l3-1.png" alt="" class="img-fluid" />
            <div class="overlay"></div>
            <div class="social">
              <ul>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-facebook center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-twitter center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-google center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-globe center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="employee">
            <h5 class="e-name text-center">Zahed Zakaria</h5>
            <h6 class="post text-center">Marketing Specialist </h6>
          </div>
        </div>
      </div>
     
      <div class="col-md-3 col-sm-6 speker-container">
        <div class="text-center">
          <div class="team-img">
            <img src="assets/images/event/l3-1.png" alt="" class="img-fluid" />
            <div class="overlay"></div>
            <div class="social">
              <ul>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-facebook center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-twitter center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-google center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-globe center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="employee">
            <h5 class="e-name text-center">Ihab Bakkar</h5>
            <h6 class="post text-center">Front End Developer </h6>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 speker-container">
        <div class="text-center">
          <div class="team-img">
            <img src="assets/images/event/l3-1.png" alt="" class="img-fluid" />
            <div class="overlay"></div>
            <div class="social">
              <ul>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-facebook center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-twitter center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-google center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i
                      class="fa fa-globe center-content"
                      aria-hidden="true"
                    ></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="employee">
            <h5 class="e-name text-center">Mahmoud Ayach</h5>
            <h6 class="post text-center">Front End Developer </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="resume counter bg-pink " >
  <div class="container" >
    <div class="row" style="justify-content: center;">
      <div class="col-xl-3 col-6 counter-container">
        <div class="counters">
          <!-- <img
            src="assets/images/resume/icon/1.png"
            alt=""
            class="img-fluid counter-img"
          /> -->
          <div class="counter-text">
            <h3 class="count-text counts">10</h3>
            <h5 class="count-desc">Satisfied Customers</h5>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-6 counter-container">
        <div class="counters">
          <!-- <img
            src="assets/images/resume/icon/2.png"
            alt=""
            class="img-fluid counter-img"
          /> -->
          <div class="counter-text">
            <h3 class="count-text counts">15</h3>
            <h5 class="count-desc">Total Products</h5>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-6 counter-container">
        <div class="counters">
          <!-- <img
            src="assets/images/resume/icon/3.png"
            alt=""
            class="img-fluid counter-img"
          /> -->
          <div class="counter-text">
            <h3 class="count-text counts">4000+</h3>
            <h5 class="count-desc">Hours Worked</h5>
          </div>
        </div>
      </div>
     
    </div>
  </div>
</section>

<app-Alzakaa-footer></app-Alzakaa-footer>

