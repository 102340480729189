import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlzakaaComponent } from './Alzakaa/Alzakaa.component';


const routes: Routes = [
  {
    path: '',
    component: AlzakaaComponent,
    data: {
      title: "App Landing Page | AlZakaa Landing Page"
    }
  }

]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutsRoutingModule { }
