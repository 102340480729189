export class blogBasicDB {
    static column = [
        {
            id: 1,
            img: 'assets/images/agency/blog/aiBlog.jpg',
            date:'Aug 1, 2024',
            type:'Ai & Ml',
            title:'AI & ML Insights',
            content:'Welcome to AI & ML Insights, your go-to blog for all things artificial intelligence and machine learning! Here, we explore the latest trends, technologies, and applications in the world of AI and ML. Whether you re a beginner or an expert, you ll find valuable content to expand your knowledge and stay up-to-date with this exciting field................',
            btn:'read more'
        },
      
    ]
}