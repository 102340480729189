import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-Alzakaa-services',
  templateUrl: './Alzakaa-services.component.html',
  styleUrls: ['./Alzakaa-services.component.scss']
})
export class AlzakaaServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


  services = [
    {
      img: "assets/images/app_landing1/icons/web.png",
      title: "Custom Software Development",
      description: "Tailored solutions designed to fit your unique business requirements."
    },
    {
      img: "assets/images/app_landing1/icons/app.png",
      title: "Mobile App Development",
      description: "High-performance iOS and Android apps that engage users and drive growth."
    },
    {
      img: "assets/images/app_landing1/icons/web.png",
      title: "Web Development",
      description: "Responsive and functional websites that enhance your online presence."
    },
    {
      img: "assets/images/app_landing1/icons/ux.png",
      title: "UI/UX Design",
      description: "Intuitive and engaging interfaces for exceptional user experiences."
    },
    {
      img: "assets/images/app_landing1/icons/support.png",
      title: "Maintenance & Support",
      description: "Ongoing services to keep your software updated and performing optimally."
    },
    {
      img: "assets/images/app_landing1/icons/digital.png",
      title: "Digital Marketing",
      description: "There are many variations of passages of Lorem Ipsum available, but the ma- jority have suffered."
    }
  ]

}
