<!-- about section Start-->

<section class="app1 about bg-theme">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                <div class="title title">
                    <div class="main-title">
                        <h2 class="text-white">About Al Zaka</h2>
                    </div>
                    <div class="sub-title">
                        <p class="text-white para">At Al zaka , we pride ourselves on being at the forefront of the digital revolution. With years of experience and a passionate team, 
                            we specialize in creating custom software solutions that cater to a diverse range of industries.
                             Our mission is to empower businesses by providing innovative tools that enhance productivity, streamline operations, and foster growth.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-7 col-lg-6 col-sm-12">
                <div class="row set-padding">
                    <div class="col-sm-6 counters" *ngFor="let data of counter">
                        <div class="abouts text-center">
                            <div  >
                                <img [src]="data.img" alt=""
                                     class="service-img img-fluid">
                                <h3 class="text-white">{{data.count}}</h3>
                                <h5 class="text-white">{{data.text}}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="set-abs mobile mobile-img bottom-0">
        <div class="set-relative">
            <img alt="" src="assets/images/app_landing1/about/about.png" class="img-fluid mobile-screen">
            <div class="set-abs mobile rectangle r-2  center-content bottom-0">
                <img src="assets/images/app_landing1/icons/1-2.png" alt="" class="img-fluid">
            </div>
            <div class="set-abs mobile rectangle center-content bottom-0">
                <img src="assets/images/app_landing1/Ellipse-2-copy-241.png" alt="" class="img-fluid">
            </div>
            <!-- <div class="set-abs mobile center-content galaxy bottom-0">
                <img src="assets/images/app_landing1/about/Layer-27.png" alt="" class="img-fluid">
            </div> -->
        </div>
    </div>
</section>

<!-- about section end-->

