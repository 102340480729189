import { Component, OnInit } from '@angular/core';
import SwiperCore, { SwiperOptions, EffectCards  } from 'swiper';
SwiperCore.use([EffectCards]);

@Component({
  selector: 'app-Alzakaa-screenshots',
  templateUrl: './Alzakaa-screenshots.component.html',
  styleUrls: ['./Alzakaa-screenshots.component.scss']
})
export class AlzakaaScreenshotsComponent implements OnInit {

public index: any;

  constructor() { }

  ngOnInit() {  }

  public config: SwiperOptions = {
        effect: 'coverflow',
        loop: true,
        centeredSlides: true,
        slidesPerView: 4,
        initialSlide: 2,
        keyboard: true,
        mousewheel: false,
        preventClicks: false,
        preventClicksPropagation: false,
        coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 1.43,
            slideShadows: false
        },
        breakpoints: {
            1199: {
                slidesPerView: 4,
                spaceBetween: 0
            },
            991: {
                slidesPerView: 4
            },
            767: {
                slidesPerView: 3
            },
            575: {
                slidesPerView: 2
            }
        },
        autoplay: {
            delay: 2500,
            disableOnInteraction: false
        },
        pagination: {
            el: '.swiper-pagination'
        }
  };

  public slides = [
    { img: 'assets/images/app_landing1/screen-shot/1.jpg'},
    { img: 'assets/images/app_landing1/screen-shot/2.jpg'},
    { img: 'assets/images/app_landing1/screen-shot/3.png'},
    { img: 'assets/images/app_landing1/screen-shot/4.jpg'},
    { img: 'assets/images/app_landing1/screen-shot/5.png'},

  ]
}
