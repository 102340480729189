<a class="overlay-sidebar-header" [class.overlay-sidebar-header-open]="openSide" (click)="closeOverlay()"></a>
<div class="responsive-btn">
    <a (click)="toggleSidebar()">
        <i class="fa fa-bars text-white" aria-hidden="true"></i>
    </a>
</div>
<div class="navbar m-l-auto" id="togglebtn" [class.openSidebar]="openSide">
    <div class="responsive-btn">
        <a (click)="toggleSidebar()">
            <h5>back</h5>
        </a>
    </div>
    <ul class="main-menu">
        <li *ngFor="let menuItem of menuItems" [class]="menuItem.megaMenu ? 'mega-menu' : ''">
            <a *ngIf="menuItem.type === 'link'" 
               [routerLink]="menuItem.routerLink" 
               routerLinkActive="active-menu" 
               [ngClass]="{ 'active-menu': isActive(menuItem.routerLink) }">
                {{menuItem.title}}
            </a>
            <a href="javascript:void(0)" *ngIf="menuItem.type === 'sub'" 
               (click)="setActive(menuItem.title)" 
               [ngClass]="{ 'active-menu': isActive(menuItem.title) }">
                {{menuItem.title}}
            </a>
        </li>
    </ul>
</div>
