
<app-Alzakaa-nav></app-Alzakaa-nav>

<section class="app1 header" id="home">
  <div class="app1-header bg" [ngStyle]="{'background-image': 'url(assets/images/app_landing1/header-1-bg.png)'}">
      <div class="container">
          <div class="row">
              <div class="col-lg-6 col-md-7">
                  <div class="center-text">
                      <div>
                          <div class="header-text">
                              <div class="sub-head">Al Zakaa Services</div>
                          </div>
                         
                      </div>
                  </div>
              </div>
             
              <div class="squares">
                  <img alt="" src="assets/images/app_landing1/Ellipse.png" class="img-fluid img-1 set-abs">
                  <img alt="" src="assets/images/app_landing1/Ellipse.png" class="img-fluid img-2 set-abs">
              </div>
              <div class="animation-circle-inverse">
                  <i></i>
                  <i></i>
                  <i></i>
              </div>
          </div>
      </div>
  </div>
</section>

<section class="services-section">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-3" *ngFor="let service of services">
          <div class="service-card">
            <i class="{{service.icon}} service-icon"></i>
            <h5 class="service-title">{{service.title}}</h5>
            <p class="service-description">{{service.description}}</p>
            <!-- <a href="#" class="service-button">{{service.button}}</a> -->
          </div>
        </div>
      </div>
    </div>
  </section>

<app-Alzakaa-footer></app-Alzakaa-footer>
  
  