import { Component } from '@angular/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactUsComponent {
  contactInfo = {
    email: 'alzakaa@gmail.com',
    phoneNumbers: [
      { location: 'Lebanon', phone: '+961 70 536 855' },
      { location: 'Dubai', phone: '+974 5584 3766' },
      { location: 'France', phone: '+33 7 69 06 47 74' }
    ],
    linkedin: 'https://www.linkedin.com/company/alzakaa-technologies'
  };
  
}
