<!-- header section Start-->
<section class="app1 header" id="home">
  <div class="app1-header bg" [ngStyle]="{'background-image': 'url(assets/images/app_landing1/header-1-bg.png)'}">
      <div class="container">
          <div class="row">
              <div class="col-lg-6 col-md-7">
                  <div class="center-text">
                      <div>
                          <div class="header-text">
                              <div class="sub-head">Empowering Your Digital Transformation</div>
                          </div>
                          <div class="header-sub-text">
                              <p class="text-white p-light">Welcome to Al zaka where innovation meets excellence. As a leading provider of cutting-edge software solutions,
                                 we are dedicated to transforming your ideas into reality. we are here to support your journey every step of the way. 
                                Join us in shaping the future of technology, today.</p>
                          </div>
                          <div class="link-horizontal btnresp">
                            <a href="mailto:alzakaa@gmail.com"> 
                            <div class="button">Let's Contact</div>
                        </a>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="rotate">
                  <img alt="" src="assets/images/app_landing1/head2.jpg" class="img-fluid" data-tilt
                       data-tilt-max="3" data-tilt-speed="400" data-tilt-perspective="500">

              </div>
              <div class="squares">
                  <img alt="" src="assets/images/app_landing1/Ellipse.png" class="img-fluid img-1 set-abs">
                  <img alt="" src="assets/images/app_landing1/Ellipse.png" class="img-fluid img-2 set-abs">
              </div>
              <div class="animation-circle-inverse">
                  <i></i>
                  <i></i>
                  <i></i>
              </div>
          </div>
      </div>
  </div>
</section>
<!-- header section end-->

