import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { blogBasicDB } from '../../../shared/data/blog/blogs/column';


@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  public blogData: any;
  public blogId: string | null = null;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.blogId = this.route.snapshot.paramMap.get('id');
    console.log(this.blogId)
    if (this.blogId) {
      this.blogData = blogBasicDB.column.find(blog => blog.id === +this.blogId);
    }
  }
}
