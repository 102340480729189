<!-- Navbar section Start-->
<header class="app1 nav-abs custom-scroll">
  <div class="container">
      <div class="row">
          <div class="col">
              <nav>
              <a href="#" class="d-inline-block m-r-auto">
                  <img src="assets/images/logo/alzaka15-removebg-preview.png" alt="" class="img-fluid" width="93" height="40">
              </a>
                <app-menu></app-menu>
              </nav>
          </div>
      </div>
  </div>
</header>
<!-- Navbar section end-->

