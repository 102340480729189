<app-Alzakaa-nav></app-Alzakaa-nav>

<section class="app1 header" id="home">
    <div class="app1-header bg" [ngStyle]="{'background-image': 'url(assets/images/app_landing1/header-1-bg.png)'}">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-7">
                    <div class="center-text">
                        <div>
                            <div class="header-text">
                                <div class="sub-head">Contact Us</div>
                            </div>
                           
                        </div>
                    </div>
                </div>
               
                <div class="squares">
                    <img alt="" src="assets/images/app_landing1/Ellipse.png" class="img-fluid img-1 set-abs">
                    <img alt="" src="assets/images/app_landing1/Ellipse.png" class="img-fluid img-2 set-abs">
                </div>
                <div class="animation-circle-inverse">
                    <i></i>
                    <i></i>
                    <i></i>
                </div>
            </div>
        </div>
    </div>
  </section>

  <section class="contact-us">
    <div class="container">
      <h2>Contact Us</h2>
      <div class="contact-info">
        <div class="contact-item">
          <i class="fa fa-envelope"></i>
          <p>Email: <a href="mailto:{{contactInfo.email}}">{{contactInfo.email}}</a></p>
        </div>
        <div *ngFor="let item of contactInfo.phoneNumbers" class="contact-item">
          <i class="fa fa-phone"></i>
          <p>{{item.location}}: <a href="tel:{{item.phone}}">{{item.phone}}</a></p>
        </div>
        <div class="contact-item">
          <i class="fa fa-linkedin"></i>
          <p>LinkedIn: <a href="{{contactInfo.linkedin}}" target="_blank">{{contactInfo.linkedin}}</a></p>
        </div>
      </div>
    </div>
  </section>
  
  
<app-Alzakaa-footer></app-Alzakaa-footer>
