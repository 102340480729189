
<!-- copyright section Start-->

<div class="app1 copyright" style="background-color: white;">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="center-content">
                    <div>
                        <h6 class="copyright-text">Copyright © 2024, Development by Al Zaka simple solutions</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- copyright section end-->
<app-tap-to-top></app-tap-to-top>
