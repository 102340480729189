<!-- pricing section Start-->

<section class="app1 pricing mb-0" id="plan" style="background: linear-gradient(#13B8EA, #4E56F3)">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
        <div class="title title1">
          <div class="main-title "  >
            <h2 class=" text-white" >price Plan</h2>
          </div>
          <div class="sub-title" >
            <p class="para" style="color: white;">
              At Al Zakaa Company, we offer flexible and competitive pricing
              plans tailored to suit businesses of all sizes. Whether you're a
              startup looking for a cost-effective solution or an established
              enterprise in need of comprehensive services, our pricing options
              are designed to provide maximum value . Contact us to discuss your
              specific requirements and find the perfect plan that aligns with
              your budget and goals.
            </p>
          </div>
        </div>
        <div style="display:flex ; justify-content: center;">
          <a href="mailto:alzakaa@gmail.com"> 
          <div class="button">Contact Us</div>
          </a>
        </div>
      </div>
    </div>
    <!-- <div class="row">
            <div class="col-12">
                <owl-carousel-o [options]="owlcarouselOptions" class="pricing-slider price-margin">
                    <ng-container *ngFor="let slide of owlcarousel1">
                        <ng-template carouselSlide class="item">
                        <div class="price-container shadows text-center">
                            <div class="price-feature-container set-relative">
                                <div class="feature-text">
                                    <h3 class="feature-text-heading font-primary">{{slide.heading}}</h3>
                                    <hr/>
                                </div>
                                <div class="price-value">
                                    <h4 class="price">{{slide.price}}</h4>
                                </div>
                                <div class="price-features">
                                    <h5 class="price-feature">{{slide.features1}}</h5>
                                    <h5 class="price-feature">{{slide.features2}}</h5>
                                    <h5 class="price-feature">{{slide.features3}}</h5></div>
                                <a href="javascript:voi(0)" class=" btn btn-default btn-gradient text-white">{{slide.btn}}</a>
                                <div class="set-abs bottom-0">
                                    <img [src]="slide.img" alt=""
                                         class="img-fluid pricing-img">
                                </div>
                            </div>
                        </div>
                      </ng-template>
                      </ng-container>
                    </owl-carousel-o> 
            </div>
        </div> -->
  </div>
</section>

<!-- pricing section end-->
