import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlzakaaComponent } from './layouts/Alzakaa/Alzakaa.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { BlogsComponent} from './pages/blogs/blogs.component';
import {ServicesComponent} from './pages/services/services.component'
import { ContactUsComponent } from './pages/contact-us/contact.component';
const routes: Routes = [
  { path: '', redirectTo: 'Alzakaa', pathMatch: 'full' },
  { path: 'Alzakaa', component: AlzakaaComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'blogs', component: BlogsComponent },
  {path: 'services' , component: ServicesComponent},
  {path: 'contact' , component: ContactUsComponent},


  { path: '**', redirectTo: '' }  // Redirect to home if no route matches
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
