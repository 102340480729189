import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  public services: any[] = [];

  constructor() {}

  ngOnInit() {
    this.services = [
      {
        icon: 'fa fa-laptop',
        title: 'Web Development',
        description: 'Whether you need a corporate website, an e-commerce platform, or a web-based application, we have the skills to deliver outstanding web solutions that enhance your online presence.',
      
      },
      {
        icon: 'fa fa-mobile',
        title: 'Mobile Apps',
        description: 'Our mobile app development services cover both iOS and Android platforms. We design and develop intuitive, high-performance apps that engage users and drive business growth.',
      },
      {
        icon: 'fa fa-cloud',
        title: 'Cloud Solutions',
        description: 'Leverage the power of cloud computing with our cloud services. We offer cloud integration, migration, and management to ensure your business operations are efficient and flexible.',
      },
      {
        icon: 'fa fa-bullhorn',
        title: 'Digital Marketing',
        description: 'Unlock your brand s potential with our comprehensive digital marketing solutions. We craft tailored strategies that encompass SEO, social media marketing, content creation, and PPC advertising to enhance your online presence and drive engagement. ',
      } ,
      {
        icon: 'fa fa-paint-brush',
        title: 'UI/UX Design',
        description: 'We prioritize user experience in all our projects. Our design team crafts visually appealing and easy-to-navigate interfaces that keep users engaged and satisfied.',
      },
      {
        icon: 'fa fa-line-chart ',
        title: 'Data Analytics and BI',
        description: 'Transform data into actionable insights with our analytics and BI services. We help you make informed decisions by providing comprehensive data analysis and reporting.',
      },
      {
        icon: 'fa fa-code',
        title: 'IT Consulting',
        description: 'Our consulting services guide you through the complexities of technology adoption. We provide strategic advice to help you choose the right technologies and implement them effectively.',
      },
      {
        icon: 'fa fa-wrench',
        title: 'Maintenance and Support',
        description: ': We offer ongoing maintenance and support to ensure your software remains up-to-date, secure, and performing optimally.',
      }
    ];
  }
}
