import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

// Menu
export interface Menu {
  path?: string;
  title?: string;
  type?: string;
  icon?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  megaMenu?: boolean;
  megaMenuType?: string; // small, medium, large
  bookmark?: boolean;
  children?: Menu[];
  routerLink?: string;
}

@Injectable({
  providedIn: "root",
})
export class NavService {
  constructor() {}

  MENUITEMS: Menu[] = [
    {
      title: "Home",
      type: "link",
      routerLink: "/home"
     
    },

    {
      title: "About us",
      type: "link",
      routerLink: "/about-us"
    },
    {
      title: "Blogs",
      type: "link",
      routerLink: "/blogs"
    },
    {
      title: "Services",
      type: "link",
      routerLink: "/services"
    },
    {
      title: "Contact us",
      type: "link",
      routerLink: "/contact",
    },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
